import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {fetchJson, formatDateTime, formatHHMM} from '../Util';
import Table from 'react-bootstrap/Table';
import { ProgressBar } from 'react-bootstrap';

interface Data {
    groupSlug: string;
    groupTitle: string;
    name: string;
    decks: Deck[];
}

interface Deck {
    deckName: string;
    stats: Stats;
    lastSeen: Date;
}

interface Stats {
    cards: Cards;
    sessions: Sessions;
}

interface Cards {
    memorised: number;
    total: number;
    reviewed: number;
}

interface Sessions {
    totalDuration: number;
}

interface DeckProps {
    deck: Deck;
}

interface UrlParams {
    groupId: string;
    studentId: string;
}

function DeckStats({ deck }: DeckProps) {
    const memPercent = 100 * deck.stats.cards.memorised / deck.stats.cards.total;
    const seenPercent = 100 * deck.stats.cards.reviewed / deck.stats.cards.total;
    
    return <>
        <h4>{deck.deckName}</h4>
        <ProgressBar>
            <ProgressBar key={2} now={memPercent} variant="success"/>
            <ProgressBar key={1} now={seenPercent-memPercent} variant="warning"/>
        </ProgressBar>
        <Table className="member-data">
            <tbody>
            <tr>
                <th>Cards seen</th>
                <td>{deck.stats.cards.reviewed} ({seenPercent.toFixed()}%)</td>
            </tr>
            <tr>
                <th>Cards memorised</th>
                <td>{deck.stats.cards.memorised} ({memPercent.toFixed()}%)</td>
            </tr>
            <tr>
                <th>Time invested</th>
                <td>{formatHHMM(deck.stats.sessions.totalDuration)}</td>
            </tr>
            <tr>
                <th>Last active</th>
                <td>{formatDateTime(deck.lastSeen)}</td>
            </tr>
            </tbody>
        </Table>
    </>;
}

function Member() {
    const { groupId, studentId } = useParams<UrlParams>();

    const query  = useQuery<Data, Error>(['student', groupId, studentId], () =>
        fetchJson(`/api/group/${groupId}/student/${studentId}`)
    );

    if (query.isLoading || query.isIdle) return <p>Loading...</p>;
    
    if (query.error) {
        if (query.error.message === 'Status 404') return <p>Student not found.</p>;
        if (query.error.message === 'Status 403') return <p>Permission denied.</p>;
        return <p>Sorry, something went wrong.</p>;
    }
    
    const data = query.data;

    return (
        <div className="member">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to={`/group/${data.groupSlug}`}>{data.groupTitle}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{data.name}</li>
                </ol>
            </nav>
            <Table bordered className="member-summary">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td>
                            {data.name}
                        </td>
                    </tr>
                </tbody>
            </Table>
            {data.decks.map(d => <DeckStats key={d.deckName} deck={d} />)}
        </div>
    );
}

export default Member;