import React from "react";
import {GridData, ProgressDetail, ViewOption} from "./Types";
import {ProgressBar} from "react-bootstrap";
import {formatDateTimeShort, formatHHMM} from "../../Util";
import Table from "react-bootstrap/Table";

function GetCount(data: GridData[], viewOption: ViewOption) {
    if (viewOption === ViewOption.DeckDetail) {
        const studentsNotStarted = data.filter(d => d.deckId === null).length;
        return `${data.length - studentsNotStarted} decks studied` + (studentsNotStarted === 0 ? '' : `, ${studentsNotStarted} students not started`);
    } else if (viewOption === ViewOption.StudentSummary) {
        return `${data.length} students`;
    }
    return null;
}

export function GetStats(data: GridData[], thisWeekStart: Date, lastWeekStart: Date, viewOption: ViewOption) {
    if (data.length === 0) return <p>No data</p>;
    
    const sum = (prev: number, curr: number) => prev + curr;
    
    const totalProgress = data
        .map(d => d.progressDetail)
        .reduce<ProgressDetail>((prev, curr) => ({
            total: prev.total + (curr?.total || 0),
            memorised: prev.memorised + (curr?.memorised || 0),
            reviewed: prev.reviewed + (curr?.reviewed || 0) 
        }), {
            total: 0,
            memorised: 0,
            reviewed: 0
        });
    
    const totalTimeInvestedAllTime = data.map(d => d.timeInvestedAllTime).reduce(sum);
    const totalTimeInvestedThisWeek = data.map(d => d.timeInvestedThisWeek).reduce(sum);
    const totalTimeInvestedLastWeek = data.map(d => d.timeInvestedLastWeek).reduce(sum);
    
    return (<>
            <ProgressBar style={{width: "350px"}}>
                <ProgressBar key={2} now={100 * (totalProgress.memorised / totalProgress.total)}
                             variant="success"/>
                <ProgressBar key={1}
                             now={100 * ((totalProgress.reviewed - totalProgress.memorised) / totalProgress.total)}
                             variant="warning"/>
            </ProgressBar>
            <p className="mt-3">{GetCount(data, viewOption)}</p>
            <Table bordered hover size="sm" className="mt-3">
                <tbody>
                    <tr>
                        <th colSpan={2}>Total</th>
                    </tr>
                    <tr>
                        <td>Time invested - all time</td>
                        <td>{formatHHMM(totalTimeInvestedAllTime)}</td>
                    </tr>
                    <tr>
                        <td>Time invested - this week ({formatDateTimeShort(thisWeekStart)})</td>
                        <td>{formatHHMM(totalTimeInvestedThisWeek)}</td>
                    </tr>
                    <tr>
                        <td>Time invested - last week ({formatDateTimeShort(lastWeekStart)})</td>
                        <td>{formatHHMM(totalTimeInvestedLastWeek)}</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Average</th>
                    </tr>
                    <tr>
                        <td>Time invested - all time</td>
                        <td>{formatHHMM(totalTimeInvestedAllTime / data.length)}</td>
                    </tr>
                    <tr>
                        <td>Time invested - this week ({formatDateTimeShort(thisWeekStart)})</td>
                        <td>{formatHHMM(totalTimeInvestedThisWeek / data.length)}</td>
                    </tr>
                    <tr>
                        <td>Time invested - last week ({formatDateTimeShort(lastWeekStart)})</td>
                        <td>{formatHHMM(totalTimeInvestedLastWeek / data.length)}</td>
                    </tr>
                </tbody>
            </Table>
        </>);
}