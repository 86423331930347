import React from "react";
import {Link} from "react-router-dom";
import {Button, ButtonGroup, Dropdown, DropdownButton, OverlayTrigger, Popover, ToggleButton} from "react-bootstrap";
import {ViewOption} from "./Types";
import {useUserContext} from "../../UserContext";

interface ToolbarProps
{
    slug: string;
    allowJson: boolean;
    anySelected: boolean;
    oneSelected: boolean;
    deleteStudents: () => void;
    renameStudent: () => void;
    viewOption: ViewOption;
    setViewOption: (viewOption: ViewOption) => void;
    toggleFilters: () => void;
    enableFiltering: boolean;
    stats: React.ReactNode;
}

const viewOptions = [
    { name: 'Student summary', value: ViewOption.StudentSummary },
    { name: 'Deck detail', value: ViewOption.DeckDetail },
];

function Toolbar(props: ToolbarProps) {
    const { user } = useUserContext();
    const popover = (
        <Popover id="popover" style={{maxWidth: "600px"}}>
            <Popover.Title as="h3">Stats</Popover.Title>
            <Popover.Content>
                {props.stats}
            </Popover.Content>
        </Popover>
    );
    
    return (
        <div className="toolbar m-1 d-flex justify-content-between">
            <div>
                <span className="mr-1 align-middle"><small>View:</small></span>
                <ButtonGroup toggle className="mr-1">
                    {viewOptions.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            type="radio"
                            variant="secondary"
                            name="radio"
                            value={radio.value}
                            checked={props.viewOption === radio.value}
                            onChange={(e) => props.setViewOption(e.currentTarget.value as ViewOption)}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                <button className="btn btn-primary" onClick={props.toggleFilters}><i className="fas fa-filter"/> {props.enableFiltering ? 'Hide Filters' : 'Show Filters'}</button>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                    <Button variant="primary"><i className="fas fa-calculator"/> Stats</Button>
                </OverlayTrigger>
                {props.anySelected && <button className="btn btn-danger" onClick={props.deleteStudents}>Delete</button>}
                {props.oneSelected && <button className="btn btn-outline-primary" onClick={props.renameStudent}>Rename</button>}
            </div>
            <div>
                { user?.isAdmin &&
                    <DropdownButton id="toolbar-admin" title={<><i className="fas fa-user-shield"/> Super-admin</>} className="d-inline" variant="info">
                        <Dropdown.Item href={`/admin/group/${props.slug}`}>Super-admin Settings</Dropdown.Item>
                        <Dropdown.Item href={`/admin/group/${props.slug}/users`}>Admin Users</Dropdown.Item>
                    </DropdownButton> }
                <DropdownButton id="toolbar-export" title="Export" className="d-inline">
                    <Dropdown.Item href={`/group/${props.slug}/csv`}>Download CSV</Dropdown.Item>
                    {props.allowJson && <Dropdown.Item href={`/group/${props.slug}/json`}>Download JSON</Dropdown.Item>}
                </DropdownButton>
                <a className="btn btn-primary" href={`/group/${props.slug}/settings`}><i className="fas fa-cog"/> Settings</a>
                <Link className="btn btn-primary" to={`/group/${props.slug}/decks`}><i className="fas fa-edit"/> Edit Decks</Link>
            </div>
        </div>
    );
}

export default Toolbar;