export async function fetchJson(url: string) {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Status ' + response.status);
    }
    return response.json();
}

export async function postJson(url: string, data?: any) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        throw new Error('Status ' + response.status);
    }
    return response.json();
}

export function formatHHMMSS(secs: number) {
    // https://stackoverflow.com/a/34841026/31280
    const hours   = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;
    const seconds = secs % 60;

    return [hours, minutes, seconds]
        .map(v => v < 10 ? '0' + v : v)
        .filter((v,i) => v !== '00' || i > 0)
        .join(':');
}

export function formatHHMM(secs: number) {
    // https://stackoverflow.com/a/34841026/31280
    const hours   = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;

    return [hours, minutes]
        .map(v => v < 10 ? '0' + v : v)
        .join(':');
}

export function formatPercent(numerator: number, denominator: number) {
    const result = 100 * (numerator / denominator);
    return result.toFixed() + '%';
}

export function formatDateTime(dateString: string | Date) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
}

export function formatDateTimeShort(dateString: string | Date) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-gb', { month: 'short', day: 'numeric' });
}

export function updateArray(selected: string[], id: string, isSelected: boolean) {
    const newSelected = [...selected];
    const index = newSelected.indexOf(id);
    if (index > -1 && !isSelected) {
        newSelected.splice(index, 1);
    } else if (index === -1 && isSelected) {
        newSelected.push(id);
    }
    return newSelected;
}

export function progress(memorised: number, reviewed: number, total: number) {
    if (total === 0) return 0;
    // sort value for progress - basically we want to sort by cards memorised, then by cards reviewed
    return (100 * memorised / total) + (reviewed / total);
}