import {TypeSingleFilterValue} from "@inovua/reactdatagrid-community/types";
import {TagCategory} from "./Types";

const baseFilterValue: TypeSingleFilterValue[] = [
    { name: 'show', type: 'boolean', operator: 'eq', value: null },
    { name: 'front', type: 'string', operator: 'contains', value: '' },
    { name: 'back', type: 'string', operator: 'contains', value: '' },
    { name: 'isKeyQuestion', type: 'boolean', operator: 'eq', value: null },
    { name: 'note', type: 'string', operator: 'contains', value: '' }
];

export function GetFilterValue(tagCategories: TagCategory[] | undefined) {
    let result = baseFilterValue;
    if (tagCategories) {
        result = result.concat(tagCategories.map(tc => ({
            name: tc.tagCategoryId,
            type: 'string',
            operator: 'contains',
            value: ''
        })))
    }
    return result;
}