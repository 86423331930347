import React from 'react';
import {Link} from "react-router-dom";

export interface BreadcrumbsProps {
    slug: string;
    groupTitle: string;
    items: BreadcrumbItem[];
    zeroMargin?: boolean;
}

export interface BreadcrumbItem {
    href?: string;
    name: string;
}

export function Breadcrumbs(props: BreadcrumbsProps) {
    const baseItems: BreadcrumbItem[] = [
        { href: "/", name: "Home" },
        { href: `/group/${props.slug}`, name: props.groupTitle }
    ];

    const breadcrumbs = [...baseItems, ...props.items];

    return (
        <nav aria-label="breadcrumb">
            <ol className={"breadcrumb" + (props.zeroMargin ? " m-0" : "")}>
                {breadcrumbs.map((value, index) => {
                    const isLast = index === breadcrumbs.length -1;
                    const className = "breadcrumb-item" + (isLast ? " active" : "");
                    const item = (isLast || !value.href) ? value.name : <Link to={value.href}>{value.name}</Link>
                    return <li className={className} key={value.href || value.name}>{item}</li>;
                })}
            </ol>
        </nav>
    );
}
