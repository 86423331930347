import {TypeSingleFilterValue} from "@inovua/reactdatagrid-community/types";

const filterValue: TypeSingleFilterValue[] = [
    { name: 'studentName', type: 'string', operator: 'contains', value: '' },
    { name: 'deckName', type: 'string', operator: 'contains', value: '' },
    { name: 'decks', type: 'number', operator: 'gte', value: null },
];

export function GetFilterValue() {
    return filterValue; 
}