import React from "react";
import {TagCategory} from "./Types";
import {Table} from "react-bootstrap";

interface TagCategoriesProps
{
    tagCategories: TagCategory[];
    toggleShowTagCategories: () => void;
    renameTagCategory: (id: string, name: string) => void;
    deleteTagCategory: (id: string) => void;
    addTagCategory: (name: string) => void;
    swapTagCategories: (id1: string, id2: string) => void;
}

function TagCategories(props: TagCategoriesProps) {
    const rename = (id: string, name: string) => {
        return () => {
            const newName = prompt("Enter a new name for this tag category:", name);
            if (newName) {
                props.renameTagCategory(id, newName);
            }
        };
    };
    
    const deleteCategory = (id: string, name: string) => {
        return () => {
            if (window.confirm(`Are you sure you want to delete the '${name}' tag category?\n\nThis will delete all tags in this category. This cannot be undone.`)) {
                props.deleteTagCategory(id);
            }
        };
    };
    
    const addCategory = () => {
        const newName = prompt("Enter a name for the new tag category:");
        if (newName) {
            props.addTagCategory(newName);
        }
    };
    
    const swap = (index1: number, index2: number) => {
        return () => {
            const cat1 = props.tagCategories[index1].tagCategoryId;
            const cat2 = props.tagCategories[index2].tagCategoryId;
            props.swapTagCategories(cat1, cat2);
        };
    }
    
    return (
        <div className="container mt-4">
            <button type="button" className="close float-right" aria-label="Close" onClick={props.toggleShowTagCategories}><span aria-hidden="true">&times;</span></button>
            <h3>Tag categories</h3>
            <Table>
                <tbody>
                {props.tagCategories.map((tc, index) =>
                    <tr key={tc.tagCategoryId}>
                        <td>{tc.name}</td>
                        <td className="text-right">
                            <button
                                disabled={index === 0}
                                className="btn btn-outline-primary ml-1"
                                onClick={swap(index, index - 1)}>
                                <i className="fas fa-arrow-up"/> Move up
                            </button>
                            <button
                                disabled={index === props.tagCategories.length - 1}
                                className="btn btn-outline-primary ml-1"
                                onClick={swap(index, index + 1)}>
                                <i className="fas fa-arrow-down"/> Move down
                            </button>
                            <button className="btn btn-primary ml-1" onClick={rename(tc.tagCategoryId, tc.name)}>Rename</button>
                            <button className="btn btn-danger ml-1" onClick={deleteCategory(tc.tagCategoryId, tc.name)}>Delete</button>
                        </td>
                    </tr>)}
                <tr>
                    <td><button className="btn btn-primary" onClick={addCategory}><i className="fas fa-plus"/> Add tag category</button></td>
                    <td/>
                </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default TagCategories;