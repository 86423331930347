import React, {ChangeEvent, FormEvent, useState} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {useQuery, useQueryClient} from 'react-query';
import {fetchJson, postJson} from '../Util';
import {Form, Button} from "react-bootstrap";

interface Data {
    groupSlug: string;
    groupTitle: string;
    name: string;
    csv: string;
    isPublished: boolean;
    category: string;
    colour: string;
    sortOrder: number;
    sortOrderOptions: SortOrderOption[];
    existingCategories: string[];
    canEditDeckContent: boolean;
    canSetCategory: boolean;
}

interface SortOrderOption {
    sortOrder: number;
    name: string;
}

interface UrlParams {
    groupId: string;
    deckId: string;
}

function DeckSettings() {
    const { groupId, deckId } = useParams<UrlParams>();
    const history = useHistory();
    const queryClient = useQueryClient();
    
    const isNew = deckId == null;
    const [validated, setValidated] = useState<boolean>( false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [name, setName] = useState<string | undefined>(isNew ? "" : undefined);
    const [csvUrl, setCsvUrl] = useState<string | undefined>(isNew ? "" : undefined);
    const [isPublished, setIsPublished] = useState<boolean | undefined>(isNew ? true : undefined);
    const [category, setCategory] = useState<string | undefined>(isNew ? "" : undefined);
    const [colour, setColour] = useState<string | undefined>(isNew ? "" : undefined);
    const [sortOrder, setSortOrder] = useState<string | undefined>(undefined);
    const [createFrom, setCreateFrom] = useState("blank-deck");
    
    const apiUrl = deckId ? `/api/group/${groupId}/decks/edit/${deckId}` : `/api/group/${groupId}/decks/add`; 
    
    const query  = useQuery<Data, Error>(['decksettings', deckId], () =>
        fetchJson(apiUrl)
    );

    if (query.isLoading || query.isIdle) return <p>Loading...</p>;

    if (query.error) {
        if (query.error.message === 'Status 404') return <p>Not found.</p>;
        if (query.error.message === 'Status 403') return <p>Permission denied.</p>;
        return <p>Sorry, something went wrong.</p>;
    }

    const data = query.data;
    if (name === undefined) setName(data.name || "");
    if (csvUrl === undefined) setCsvUrl(data.csv || "");
    if (isPublished === undefined) setIsPublished(data.isPublished);
    if (category === undefined) setCategory(data.category || "");
    if (colour === undefined) setColour(data.colour || "");
    if (sortOrder === undefined) setSortOrder(data.sortOrder.toString());
    
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (form.checkValidity()) {
            setIsSubmitting(true);
            const postData = {
                name,
                csv: (csvUrl !== undefined && csvUrl?.length > 0) ? csvUrl : null,
                isPublished,
                category,
                colour,
                sortOrder
            };
            console.log(postData);
            
            postJson(apiUrl, postData).then(response => {
                if (response.success) {
                    queryClient.invalidateQueries(['decksettings', deckId]);
                    if (isNew) {
                        history.push(`/group/${groupId}/decks/editor/${response.deckId}`);
                    }
                    else {
                        history.push(`/group/${groupId}/decks`);
                    }
                } else {
                    alert(response.error);
                }
                setIsSubmitting(false);
            }).catch(response => {
                alert(response);
                setIsSubmitting(false);
            });
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };
    
    const onCreateFromChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setCreateFrom(e.target.value);
    };
    
    return (
        <div className="mb-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to={`/group/${data.groupSlug}`}>{data.groupTitle}</Link></li>
                    <li className="breadcrumb-item"><Link to={`/group/${data.groupSlug}/decks`}>Decks</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{ isNew ? "Add Deck" : data.name }</li>
                </ol>
            </nav>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="isPublished">
                    <Form.Check type="checkbox" label="Is Published?" checked={isPublished} onChange={e => setIsPublished(e.target.checked)} />
                </Form.Group>
                {data.canSetCategory &&
                    <>
                        <Form.Group controlId="category">
                            <Form.Label>Category</Form.Label>
                            <Form.Control type="text" value={category} onChange={e => setCategory(e.target.value)} list="categories"/>
                        </Form.Group>
                        <datalist id="categories">
                            {data.existingCategories.map(c => <option key={c} value={c}/>)}
                        </datalist>
                    </>
                }
                <Form.Group controlId="colour">
                    <Form.Label>Colour</Form.Label>
                    <Form.Control type="text" pattern="#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})" title="Colour must be a valid HTML colour code" value={colour} onChange={e => setColour(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="position">
                    <Form.Label>Position</Form.Label>
                    <Form.Control as="select" value={sortOrder} onChange={e => setSortOrder(e.target.value)}>
                        {data.sortOrderOptions.map(so => <option key={so.sortOrder} value={so.sortOrder}>{so.name}</option>)}
                    </Form.Control>
                </Form.Group>
                {isNew &&
                    <>
                        <Form.Group>
                            <Form.Check inline label="Blank deck" name="create-from" type="radio" value="blank-deck" checked={createFrom === 'blank-deck'} onChange={onCreateFromChanged} id="create-from-blank-deck" />
                            <Form.Check inline label="Import from CSV" name="create-from" type="radio" value="csv" checked={createFrom === 'csv'} onChange={onCreateFromChanged} id="create-from-csv" />
                        </Form.Group>
                        {createFrom === 'csv' &&
                            <Form.Group controlId="csvUrl">
                                <Form.Label>CSV URL</Form.Label>
                                <Form.Control type="url" required value={csvUrl} onChange={e => setCsvUrl(e.target.value)} />
                            </Form.Group>
                        }
                    </>
                }
                <Button variant="primary" type="submit" disabled={isSubmitting}>{ isNew ? "Add" : "Update" }</Button>
            </Form>
            {
                data.canEditDeckContent && (
                    <Link className="mt-2 btn btn-primary" to={`/group/${data.groupSlug}/decks/editor/${deckId}`}>Edit Deck</Link>
                )
            }
        </div>
    );
}

export default DeckSettings;