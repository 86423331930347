import React, {useContext, useEffect, useState} from 'react';

interface UserContextValues {
    user: User | null;
}

interface User {
    isAuthenticated: boolean;
    userName: string;
    environment: string;
    navbarLinks: NavBarLink[];
    isAdmin: boolean;
}

export interface NavBarLink {
    title: string;
    href: string;
}

const UserContext = React.createContext<UserContextValues>({
    user: null
});

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        async function getUser() {
            const response = await fetch('/user');
            setUser(await response.json());
        }
        getUser();
    }, []);

    const userContext = {
        user
    };

    return (
        <UserContext.Provider value={userContext}>
            {children}
        </UserContext.Provider>
    );
}

export const useUserContext = () => useContext(UserContext);