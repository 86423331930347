import {Link} from "react-router-dom";
import {formatDateTime, formatDateTimeShort, formatHHMM} from "../../Util";
import {ProgressBar} from "react-bootstrap";
import React from "react";
import {TypeColumn} from "@inovua/reactdatagrid-community/types/TypeColumn";
import {GridData, Student, ViewOption} from "./Types";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";

const compareDate = (date1: Date, date2: Date) => {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    if (d1.getTime() === d2.getTime()) return 0;
    if (d1 > d2) return 1;
    if (d1 < d2) return -1;
};

export function GetColumns(studentUrlPrefix: string, thisWeekStart: Date, lastWeekStart: Date, viewOption: ViewOption) {
    let columns: TypeColumn[] = [{
        name: 'studentName',
        header: 'Student',
        defaultFlex: 1,
        render: ({data}: { data: Student }) => <Link
            to={`${studentUrlPrefix}/student/${data.studentId}`}>{data.studentName}</Link>
    }];

    if (viewOption === ViewOption.DeckDetail) {
        columns.push({
            name: 'deckName',
            header: 'Deck',
            defaultFlex: 2
        });
    } else {
        columns.push({
            name: 'decks',
            header: 'Decks',
            type: 'number',
            defaultFlex: 1,
            filterEditor: NumberFilter
        });
    }

    columns = [
        ...columns,
        {
            name: 'lastSeen',
            header: 'Last Seen',
            defaultFlex: 1,
            render: ({value}: { value: Date }) => formatDateTime(value),
            sort: (d1: Date, d2: Date) => compareDate(d1, d2)
        },
        {
            name: 'progress',
            header: 'Progress',
            defaultFlex: 2,
            type: 'number',
            render: ({data}: { data: GridData }) => data.progressDetail == null ? <></> :
                <ProgressBar>
                    <ProgressBar key={2} now={100 * (data.progressDetail.memorised / data.progressDetail.total)}
                                 variant="success"/>
                    <ProgressBar key={1}
                                 now={100 * ((data.progressDetail.reviewed - data.progressDetail.memorised) / data.progressDetail.total)}
                                 variant="warning"/>
                </ProgressBar>
        },
        {
            name: 'cards',
            header: 'Cards',
            type: 'number',
            defaultFlex: 0.5,
        },
        {
            name: 'timeInvestedAllTime',
            header: 'All time',
            group: 'timeInvested',
            defaultFlex: 1,
            type: 'number',
            render: ({value}: { value: number }) => formatHHMM(value),
        },
        {
            name: 'timeInvestedThisWeek',
            header: `This week (${formatDateTimeShort(thisWeekStart)})`,
            group: 'timeInvested',
            defaultFlex: 1,
            type: 'number',
            render: ({value}: { value: number }) => formatHHMM(value),
        },
        {
            name: 'timeInvestedLastWeek',
            header: `Last week (${formatDateTimeShort(lastWeekStart)})`,
            group: 'timeInvested',
            defaultFlex: 1,
            type: 'number',
            render: ({value}: { value: number }) => formatHHMM(value),
        },
    ];

    return columns;
}