import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {NavBarLink} from "../UserContext";

export default function NavMenu({ links } : { links: NavBarLink[] }) {
    return (
        <header>
            <Navbar className="mb-2" bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/">RememberMore Dashboard</Navbar.Brand>
                    <Nav className="mr-auto">
                        {/* <Nav.Link as={Link} className="text-dark" to="/">Home</Nav.Link>
                        <Nav.Link as={Link} className="text-dark" to="/group">Group</Nav.Link> */}
                        {links.map(link => (
                            <Nav.Link key={link.href} className="text-dark" href={link.href}>{link.title}</Nav.Link>
                        ))}
                    </Nav>
                </Container>
            </Navbar>
        </header>
    );
}

NavMenu.propTypes = {
    links: PropTypes.array
};