import React from 'react';
import {useParams, useRouteMatch} from 'react-router-dom';
import GroupViewer from '../components/group-viewer/GroupViewer';

interface UrlParams {
    groupId: string;
}

function GroupViewerScreen() {
    const { groupId } = useParams<UrlParams>();
    const match = useRouteMatch();

    return (
        <GroupViewer groupId={groupId} studentUrlPrefix={match.url} />
    );
}

export default GroupViewerScreen;