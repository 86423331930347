import React, {useCallback, useMemo, useState} from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import filter from '@inovua/reactdatagrid-community/filter';
import {useApi, useApiMutation} from "../../Api";
import {Breadcrumbs} from "../Breadcrumbs";
import LayoutFullScreen from "../LayoutFullScreen";
import Toolbar from "./Toolbar";
import {GetColumns} from "./Columns";
import {GridData, Student, ViewOption} from "./Types";
import {GetFilterValue} from "./Filters";
import {TypeSingleFilterValue} from "@inovua/reactdatagrid-community/types";
import {GetStats} from "./Stats";
import {progress} from "../../Util";

interface GroupViewerProps {
    groupId: string;
    studentUrlPrefix: string;
}

interface Data {
    students: Student[];
    title: string;
    slug: string;
    allowJson: boolean;
    thisWeekStart: Date;
    lastWeekStart: Date;
}

interface RenameStudentModel {
    studentId: string;
    name: string;
}

interface Selected {
    [id: string]: Student;
}

const GetGridData = (students: Student[], viewOption: ViewOption): GridData[] => {
    if (viewOption === ViewOption.DeckDetail) {
        return students.map(student => ({
            ...student,
            deckName: student.deckName || '',
            decks: null,
            timeInvestedAllTime: student.stats == null ? 0 : student.stats.sessions.totalDuration,
            timeInvestedThisWeek: student.periodDurations == null ? 0 : student.periodDurations.thisWeek,
            timeInvestedLastWeek: student.periodDurations == null ? 0 : student.periodDurations.lastWeek,
            progress: student.stats == null ? null : progress(student.stats.cards.memorised, student.stats.cards.reviewed, student.stats.cards.total),
            cards: student.stats == null ? null: student.stats.cards.total,
            progressDetail: student.stats == null ? null : {
                memorised: student.stats.cards.memorised,
                reviewed: student.stats.cards.reviewed,
                total: student.stats.cards.total,
            },
        }));
    } else if (viewOption === ViewOption.StudentSummary) {
        // Each "student" passed in to this function is really a student-deck combination, so we want to group
        // this to get a summary of all decks for each student.
        
        const groupedStudents = students.reduce(
            (map, student) => map.set(student.studentId, [...map.get(student.studentId) || [], student]),
            new Map<string, Student[]>()
        );

        const sum = (prev: number, curr: number) => prev + curr;
        const latestDate = (prev: Date, curr: Date) => curr > prev ? curr : prev;
        
        const groupedStudentsArray = Array.from(groupedStudents.values()).map(deckStats => ({
            studentId: deckStats[0].studentId,
            studentName: deckStats[0].studentName,
            decks: deckStats.filter(ds => ds.deckId != null).length,
            deckId: null,
            deckName: null,
            lastSeen: deckStats.map(ds => ds.lastSeen).reduce(latestDate),
            timeInvestedAllTime: deckStats.map(ds => ds.stats?.sessions.totalDuration ?? 0).reduce(sum),
            timeInvestedLastWeek: deckStats.map(ds => ds.periodDurations == null ? 0 : ds.periodDurations.lastWeek).reduce(sum),
            timeInvestedThisWeek: deckStats.map(ds => ds.periodDurations == null ? 0 : ds.periodDurations.thisWeek).reduce(sum),
            cards: deckStats[0].stats == null ? null : deckStats.map(ds => ds.stats == null ? 0 : ds.stats.cards.total).reduce(sum),
            progressDetail: deckStats[0].stats == null ? null : {
                memorised: deckStats.map(ds => ds.stats == null ? 0 : ds.stats.cards.memorised).reduce(sum),
                reviewed: deckStats.map(ds => ds.stats == null ? 0 : ds.stats.cards.reviewed).reduce(sum),
                total: deckStats.map(ds => ds.stats == null ? 0 : ds.stats.cards.total).reduce(sum),
            }
        }));

        return groupedStudentsArray.map(deckStats => ({
            ...deckStats,
            progress: deckStats.progressDetail == null ? null : progress(deckStats.progressDetail.memorised, deckStats.progressDetail.reviewed, deckStats.progressDetail.total),
        }));
    }

    throw new Error("Invalid view option");
};

export default function GroupViewer({groupId, studentUrlPrefix}: GroupViewerProps) {
    const [selected, setSelected] = useState<Selected>({});
    const [viewOption, setViewOption] = useState<ViewOption>(ViewOption.DeckDetail);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const queryKey = ['group', groupId];

    const api = useApi<Data>(`/api/group/${groupId}`, queryKey);
    const deleteMutation = useApiMutation<string[]>(`/api/group/${groupId}/students/delete`, queryKey, () => setSelected({}));
    const renameMutation = useApiMutation<RenameStudentModel>(`/api/group/${groupId}/students/rename`, queryKey);

    const onSelectionChange = useCallback(({selected}) => {
        setSelected(selected)
    }, []);

    const data = api.data;
    const thisWeekStart = data?.thisWeekStart;
    const lastWeekStart = data?.lastWeekStart;

    const columns = useMemo(() => {
        if (thisWeekStart == null || lastWeekStart == null) return [];
        return GetColumns(studentUrlPrefix, thisWeekStart, lastWeekStart, viewOption);
    }, [studentUrlPrefix, thisWeekStart, lastWeekStart, viewOption]);

    const defaultFilterValue = useMemo(() => GetFilterValue(), []);
    const onFilterValueChange = useCallback((filter: TypeSingleFilterValue[] | null) => {
        if (!filter) filter = [];
        setFilterValue(filter);
    }, []);
    
    const [filterValue, setFilterValue] = useState(defaultFilterValue);

    if (!data) return <p>{api.message}</p>;

    const toggleFilters = () => {
        setEnableFiltering(!enableFiltering);
    };
    
    const updateViewOption = (viewOption: ViewOption) => {
        setViewOption(viewOption);

        // Clear the filtering for deck count
        // or deck name, as the other columns remain when the
        // view option is toggled.
        for (const f of filterValue) {
            if (f.name === 'deckName') {
                f.value = '';
            } else if (f.name === 'decks') {
                f.value = null;
            }
        }
        
        setFilterValue(filterValue);
    };

    const selectedStudents = Object.keys(selected);

    const deleteStudents = () => {
        const thisUser = selectedStudents.length === 1 ? 'this user' : `these ${selectedStudents.length} users`;
        if (!window.confirm(
            `Are you sure you want to delete ${thisUser}?

- Their data will be permanently deleted
- They will lose access to this group

This is permanent, with no undo.`)) return;

        deleteMutation.mutate(selectedStudents);
    };

    const renameStudent = () => {
        const student = data.students.find(s => s.studentId === selectedStudents[0]);
        if (!student) return;
        const newName = prompt("Enter a new name for this student:", student.studentName);
        if (newName) {
            renameMutation.mutate({
                studentId: student.studentId,
                name: newName
            });
        }
    };

    const oneSelected = selectedStudents.length === 1;
    const anySelected = selectedStudents.length > 0;

    const groups = [
        {name: 'timeInvested', header: 'Time invested'}
    ];
    
    const gridData = GetGridData(data.students, viewOption);
    const gridDataSource = filter(gridData, filterValue) as GridData[];
    const stats = GetStats(gridDataSource, data.thisWeekStart, data.lastWeekStart, viewOption);

    return (
        <LayoutFullScreen>
            <Breadcrumbs slug={data.slug} groupTitle={data.title} items={[]} zeroMargin={true}/>
            <Toolbar
                slug={data.slug}
                allowJson={data.allowJson}
                oneSelected={oneSelected}
                anySelected={anySelected}
                deleteStudents={deleteStudents}
                renameStudent={renameStudent}
                viewOption={viewOption}
                setViewOption={updateViewOption}
                enableFiltering={enableFiltering}
                toggleFilters={toggleFilters}
                stats={stats}
            />
            <ReactDataGrid
                idProperty="studentId"
                columns={columns}
                selected={selected}
                checkboxColumn
                onSelectionChange={onSelectionChange}
                dataSource={gridDataSource}
                groups={groups}
                showColumnMenuTool={true}
                showColumnMenuLockOptions={false}
                showColumnMenuGroupOptions={false}
                filterValue={filterValue}
                onFilterValueChange={onFilterValueChange}
                enableFiltering={enableFiltering}
            />
        </LayoutFullScreen>
    );
}
