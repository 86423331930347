import React, {useState, useCallback} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {BreadcrumbItem, Breadcrumbs} from "../components/Breadcrumbs";
import {useApi} from "../Api";
import {Form, Button} from "react-bootstrap";
import {postJson, updateArray} from "../Util";

interface Data {
    slug: string;
    title: string;
    groupDecks: GroupDeck[];
}

interface GroupDeck {
    slug: string;
    deckId: string;
    name: string;
}

interface UrlParams {
    groupId: string;
}

export default function DeckClone() {
    const { groupId } = useParams<UrlParams>();
    const history = useHistory();
    const [selectedDecks, setSelectedDecks] = useState<string[]>([]);
    const [isWorking, setIsWorking] = useState(false);

    const onChange = useCallback(
        event => {
            const id = event.target.id;
            const isChecked = event.target.checked;
            setSelectedDecks(updateArray(selectedDecks, id, isChecked));
        },
        [selectedDecks, setSelectedDecks]
    );
    
    const api = useApi<Data>(`/api/group/${groupId}/decks/clone`, 'clone');
    if (!api.data) return <p>{api.message}</p>;

    const data = api.data;
    
    const breadcrumbs: BreadcrumbItem[] = [
        { href: `/group/${data.slug}/decks`, name: "Decks" },
        { name: "Clone decks" }
    ];

    const submit = async (url: string) => {
        setIsWorking(true);
        await postJson(url, selectedDecks);
        history.push(`/group/${groupId}/decks`);
    };
    
    const clone = async () => {
        await submit(`/api/group/${groupId}/decks/clone`);
    };

    const link = async () => {
        await submit(`/api/group/${groupId}/decks/link`);
    };
    
    return (
        <div className="mb-3">
            <Breadcrumbs slug={data.slug} groupTitle={data.title} items={breadcrumbs}/>
            <div className="alert alert-info" role="alert">
                <i className="fas fa-user-shield"/> This page is only visible to super-admins.
            </div>
            <ul>
                {data.groupDecks.map(gd =>
                    <Form.Check type='checkbox' id={gd.deckId} key={gd.deckId} label={`${gd.slug}: ${gd.name}`} onChange={onChange} checked={selectedDecks.indexOf(gd.deckId) > -1} />)}
            </ul>
            <p>{selectedDecks.length} selected</p>
            <Button disabled={selectedDecks.length === 0 || isWorking} onClick={clone} className="mr-1">Clone decks</Button>
            <Button disabled={selectedDecks.length === 0 || isWorking} onClick={link}>Link decks</Button>
            {isWorking && <span className="ml-3 spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
        </div>
    );
}