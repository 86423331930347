import React, {ButtonHTMLAttributes} from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";

interface ToolbarProps
{
    toggleFilters: () => void;
    toggleShowTagCategories: () => void;
    enableFiltering: boolean;
    status: string;
    addCard: () => void;
    deleteCard: () => void;
    isCardSelected: boolean;
    isLoading: boolean;
    classroomUrl: string;
}

function Toolbar(props: ToolbarProps) {
    const isCardSelectedOpts : ButtonHTMLAttributes<HTMLButtonElement> = { };
    if (!props.isCardSelected) isCardSelectedOpts.disabled = true;
    
    return (
        <div className="toolbar m-1 d-flex justify-content-between">
            <div>
                <button className="btn btn-primary"
                        onClick={props.toggleFilters}>{props.enableFiltering ? 'Hide Filters' : 'Show Filters'}</button>
                <button className="btn btn-primary" onClick={props.toggleShowTagCategories}>Edit Tag Categories</button>
                <button className="btn btn-primary" onClick={props.addCard}><i className="fas fa-plus"/> Add card</button>
                <button className="btn btn-primary" onClick={props.deleteCard} {...isCardSelectedOpts}><i className="fas fa-trash"/> Delete card</button>
                {props.isLoading &&
                    <button className="btn" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                        <span className="sr-only">Loading...</span>
                    </button>
                }
                <span>{props.status}</span>
            </div>
            
            <div>
                <a className="btn btn-outline-primary" href={props.classroomUrl} target="_blank" rel="noopener noreferrer">Open in Classroom</a>
                <DropdownButton id="help-menu" title="Help" className="d-inline">
                    <Dropdown.Item href="https://www.remembermore.app/educators/deck-design" target="_blank" rel="noopener noreferrer">Deck design</Dropdown.Item>
                    <Dropdown.Item href="https://www.remembermore.app/educators" target="_blank" rel="noopener noreferrer">RememberMore for Educators</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    );
}

export default Toolbar;