export interface Student {
    studentName: string;
    studentId: string;
    deckName: string;
    deckId: string;
    stats: Stats;
    lastSeen: Date;
    periodDurations: PeriodDurations
}

export interface PeriodDurations {
    thisWeek: number;
    lastWeek: number;
}

export interface Stats {
    cards: Cards;
    sessions: Sessions;
}

export interface Cards {
    memorised: number;
    total: number;
    reviewed: number;
}

export interface Sessions {
    totalDuration: number;
}

export enum ViewOption {
    StudentSummary = "student-summary",
    DeckDetail = "deck-detail"
}

export interface GridData {
    studentId: string;
    lastSeen: Date;
    decks: number | null;
    deckId: string | null;
    studentName: string;
    progress: number | null;
    timeInvestedAllTime: number;
    timeInvestedLastWeek: number;
    timeInvestedThisWeek: number;
    progressDetail: ProgressDetail | null;
}

export interface ProgressDetail {
    memorised: number;
    reviewed: number;
    total: number;
}