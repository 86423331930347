import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Overview from './screens/Overview';
import Group from './screens/Group';
import Student from './screens/Student';
import DeckEditor from './screens/DeckEditor';
import DeckSettings from './screens/DeckSettings';
import Decks from './screens/Decks';
import DeckClone from './screens/DeckClone';
import {UserContextProvider} from './UserContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

import './custom.css';

function isError(error: unknown): error is Error {
    return error instanceof Error;
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error: unknown) => {
                if (failureCount >= 3) return false;
                if (!isError(error)) return false;
                if (error.message === 'Status 404') return false;
                if (error.message === 'Status 403') return false;
                return true;
            }
        }
    }
});

function App() {
    return (
        <UserContextProvider>
            <QueryClientProvider client={queryClient}>
                <Switch>
                    <Route path="/group/:groupId/decks/editor/:deckId" component={DeckEditor} />
                    <Route exact path="/group/:groupId" component={Group} />
                    <Route>
                        <Layout>
                            <Switch>
                                <Route exact path='/' component={Overview} />
                                <Route path="/group/:groupId/student/:studentId" component={Student} />
                                <Route path="/group/:groupId/decks/add" component={DeckSettings} />
                                <Route path="/group/:groupId/decks/edit/:deckId" component={DeckSettings} />
                                <Route path="/group/:groupId/decks/clone" component={DeckClone} />
                                <Route path="/group/:groupId/decks" component={Decks} />
                                <Route path="*">
                                    <p>Not found</p>
                                </Route>
                            </Switch>
                        </Layout>
                    </Route>
                </Switch>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </UserContextProvider>
    );
}

export default withAITracking(reactPlugin, App);