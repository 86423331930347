import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import NavMenu from './NavMenu';
import preval from 'preval.macro';
import { useUserContext } from '../UserContext';

export default function Layout({ children }: { children: React.ReactNode }) {
    const { user } = useUserContext();

    if (user === null) {
        return null;
    }

    if (!user.isAuthenticated) {
        window.location.replace('/account/signin');
        return null;
    }
    
    const year = preval`module.exports = new Date().getYear() - 100`;
    const month = preval`module.exports = new Date().getMonth() + 1`;
    const day = preval`module.exports = new Date().getDate()`;
    const build = preval`module.exports = Math.floor(new Date() / 1000);`

    return (
        <>
            <NavMenu links={user.navbarLinks} />
            <Container fluid>
                {children}
            </Container>
            <footer className="border-top footer text-muted">
                <div className="container-fluid">
                    <form method="post" id="logout_form" action="/account/signout">
                        <p>
                            Signed in as {user.userName}
                            {' '}&middot;{' '}
                            <button type="submit" className="btn btn-link" style={{padding: 0, verticalAlign: 'baseline'}}>Sign out</button>
                        </p>
                    </form>
                    <p>&copy; {(new Date()).getFullYear()} RememberMore Ltd &middot; {user.environment} &middot; v{year}.{month}.{day}.{build}</p>
                </div>
            </footer>
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.object
};