import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {fetchJson, postJson} from '../Util';
import { useUserContext } from '../UserContext';

interface Data {
    slug: string;
    title: string;
    showCategory: boolean;
    decks: Deck[];
}

interface Deck {
    deckId: string;
    name: string;
    isPublished: string;
    category: string;
    colour: string;
    canEditDeckContent: boolean;
}

interface UrlParams {
    groupId: string;
}

function Decks() {
    const { groupId } = useParams<UrlParams>();
    const { user } = useUserContext();
    const queryClient = useQueryClient();

    const query  = useQuery<Data, Error>('decks', () =>
        fetchJson(`/api/group/${groupId}/decks`)
    );

    const deleteDeckFn = (id: string) => postJson(`/api/group/${groupId}/decks/delete/${id}`);
    const deleteDeckMutation = useMutation<void, Error, string>(deleteDeckFn, {
        onSuccess: () => {
            queryClient.invalidateQueries('decks');
        }
    });

    if (query.isLoading || query.isIdle) return <p>Loading...</p>;

    if (query.error) {
        if (query.error.message === 'Status 404') return <p>Not found.</p>;
        if (query.error.message === 'Status 403') return <p>Permission denied.</p>;
        return <p>Sorry, something went wrong.</p>;
    }

    const data = query.data;
    
    const deleteDeck = (id: string, name: string) => {
        return () => {
            if (window.confirm(`Are you sure you want to delete the deck ${name}?`)) {
                deleteDeckMutation.mutate(id);
            }
        };
    };

    return (
        <div className="mb-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to={`/group/${data.slug}`}>{data.title}</Link></li>
                    <li className="breadcrumb-item active">Decks</li>
                </ol>
            </nav>
            <div className="mb-2">
                <Link className="btn btn-primary mr-1" to={`/group/${data.slug}/decks/add`}>Add deck</Link>
                { user?.isAdmin && <Link className="btn btn-outline-info" to={`/group/${data.slug}/decks/clone`}><i className="fas fa-user-shield"/> Clone / link decks</Link> }
            </div>

            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Published</th>
                    {data.showCategory && <th scope="col">Category</th>}
                    <th scope="col">Colour</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                    {data.decks.map(deck => (
                        <tr key={deck.deckId}>
                            <td>{deck.name}</td>
                            <td>{deck.isPublished ? "Published" : "Draft"}</td>
                            {data.showCategory &&
                                <td>{deck.category}</td>
                            }
                            <td><span style={{color: deck.colour}}>{deck.colour}</span></td>
                            <td className="text-right">
                                {deck.canEditDeckContent &&
                                    <Link to={`/group/${data.slug}/decks/editor/${deck.deckId}`}
                                       className="btn btn-outline-primary"><i className="fas fa-edit"/> Edit cards</Link>
                                }
                                <Link to={`/group/${data.slug}/decks/edit/${deck.deckId}`}
                                   className="btn btn-outline-primary ml-1"><i className="fas fa-cog"/> Settings</Link>
                                <button onClick={deleteDeck(deck.deckId, deck.name)} className="btn btn-outline-danger js-delete-confirm ml-1"
                                        data-name="@deck.Name"><i className="fas fa-trash"/> Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Decks;