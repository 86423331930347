import {TypeColumn} from "@inovua/reactdatagrid-community/types/TypeColumn";
import BoolEditor from "@inovua/reactdatagrid-community/BoolEditor";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";
import React, {KeyboardEvent} from "react";
import {GridCard, TagCategory} from "./Types";
import {Form, ProgressBar} from "react-bootstrap";

type TypeColumnCustomEditor = TypeColumn &
{
    editor?: any;
    cellDOMProps?: any;
}

const singleClickEdit = (gridRef: any, cellProps: any) => ({
    onClick: () => {
        gridRef.current.startEdit({columnId: cellProps.id, rowIndex: cellProps.rowIndex});
    }
});

const baseColumns = (gridRef: any) : TypeColumnCustomEditor[] => [
    { 
        name: 'show',
        header: () => <i className="far fa-check-circle"/>,
        maxWidth: 58,
        defaultFlex: 1,
        render: ({ value } : { value: boolean }) => value ? <i className="far fa-check-circle"/> : <i className="far fa-circle"/>,
        editor: BoolEditor,
        filterEditor: BoolFilter,
        hideable: false,
        cellDOMProps: (cellProps: any) => singleClickEdit(gridRef, cellProps)
    },
    {
        name: 'warnings',
        header: () => <i className="fas fa-info"/>,
        maxWidth: 42,
        defaultFlex: 1,
        editable: false,
        sortable: false,
        render: ({ data } : { data: GridCard }) => data.isDuplicateFront || data.isDuplicateBack ? <i className="fas fa-exclamation-triangle"/> : '',
        hideable: false
    },
    {
        name: 'progress',
        header: 'Progress',
        type: 'number',
        minWidth: 110,
        defaultFlex: 1,
        editable: false,
        render: ({ data }: { data: GridCard }) =>
            <ProgressBar>
                <ProgressBar key={2} now={100 * (data.memorised / data.studentCount)}
                             variant="success"/>
                <ProgressBar key={1}
                             now={100 * ((data.reviewed - data.memorised) / data.studentCount)}
                             variant="warning"/>
            </ProgressBar>
    },
    {
        name: 'front',
        header: 'Question',
        minWidth: 50,
        defaultFlex: 4,
        render: ({ value, data } : { value: string, data: GridCard }) => data.isDuplicateFront ? <span className="bg-warning">{value}</span> : value,
        hideable: false
    },
    {
        name: 'back',
        header: 'Answer',
        minWidth: 50,
        defaultFlex: 4,
        render: ({ value, data } : { value: string, data: GridCard }) => data.isDuplicateBack ? <span className="bg-warning">{value}</span> : value,
        hideable: false
    },
    {
        name: 'isKeyQuestion',
        header: '🔑',
        maxWidth: 58,
        defaultFlex: 1,
        render: ({ value } : { value: boolean }) => value ? '🔑' : '',
        editor: BoolEditor,
        filterEditor: BoolFilter,
        cellDOMProps: (cellProps: any) => singleClickEdit(gridRef, cellProps)
    },
    { name: 'note', header: 'Note', minWidth: 50, defaultFlex: 2 }
];

export function GetColumns(gridRef: any, tagCategories: TagCategory[] | undefined)  {
    let result = baseColumns(gridRef);
    if (tagCategories) {
        result = result.concat(tagCategories.map(tc => ({
            name: tc.tagCategoryId,
            header: tc.name,
            minWidth: 50,
            defaultFlex: 1,
            render: ({ value } : { value: string }) => <span className="badge badge-primary">{value}</span>,
            renderEditor: (editorProps: any) => {
                return (
                    <Form.Control
                        type="text"
                        size="sm"
                        list={tc.tagCategoryId}
                        key={tc.tagCategoryId}
                        tabIndex={0}
                        autoFocus
                        onChange={(e) => {
                            editorProps.onChange(e.target.value)
                        }}
                        onBlur={editorProps.onComplete}
                        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Tab') {
                                editorProps.onTabNavigation(true, e.shiftKey ? -1 : 1);
                            }
                            if (e.key === 'Enter') {
                                editorProps.onComplete();
                            }
                        }}
                        value={editorProps.value}
                    >
                    </Form.Control>
                );
            }
        })));
    }
    return result;
}