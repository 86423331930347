import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

interface Group {
    slug: string;
    name: string;
}

interface Groups {
    groups: Group[]
}

interface OverviewData {
    needsEmailVerification: boolean;
    groupDecks: Group[];
}

function OverviewTable({ groups } : Groups) {
    return (
        <Table>
            <thead>
                <tr>
                    <th>Group</th>
                </tr>
            </thead>
            <tbody>
                {groups.map(c => (
                    <tr key={c.slug}>
                        <td><Link to={`/group/${c.slug}`}>{c.name}</Link></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

function NeedsEmailVerification() {
    return <div>
        <p>Thanks for signing up! To get started, please verify your email address by clicking the link in the email we just sent to you.</p>
    </div>;
}

function Overview() {
    const query  = useQuery<OverviewData, Error>('overview', () =>
        fetch('/api/overview').then(res => res.json())
    );

    if (query.isLoading || query.isIdle) return <p>Loading...</p>;
    if (query.error) return <p>Sorry, something went wrong.</p>;

    const data = query.data;

    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">Home</li>
                </ol>
            </nav>
            {data.needsEmailVerification ? <NeedsEmailVerification/> : <OverviewTable groups={data.groupDecks}/>}
        </>
    );
}

export default Overview;

OverviewTable.propTypes = {
    groups: PropTypes.array
};