import {QueryKey, useMutation, useQuery, useQueryClient} from "react-query";
import {fetchJson, postJson} from "./Util";

interface ApiResult<T> {
    message?: string;
    data?: T;
}

export function useApi<T>(apiUrl: string, queryKey: QueryKey): ApiResult<T> {
    const query  = useQuery<T, Error>(queryKey, () =>
        fetchJson(apiUrl)
    );

    if (query.isLoading || query.isIdle) return { message: "Loading..." };

    if (query.error) {
        if (query.error.message === 'Status 404') return { message: "Not found." };
        if (query.error.message === 'Status 403') return { message: "Permission denied." };
        return { message: "Sorry, something went wrong." };
    }

    return { data: query.data };
}

export function useApiMutation<T>(url: string, queryKey: QueryKey, onSuccess?: () => void) {
    const queryClient = useQueryClient();
    const postFn = (data: T) => postJson(url, data);

    return useMutation<void, Error, T>(postFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            if (onSuccess) onSuccess();
        }
    });
}