import React from 'react';

function LayoutFullScreen({ children }: { children: React.ReactNode }) {
    return (
        <div className="d-flex vh-100 flex-column">
            {children}
        </div>
    );
}

export default LayoutFullScreen;