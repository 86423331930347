import React from 'react';
import {Link} from "react-router-dom";

interface BreadcrumbsProps {
    slug: string;
    groupTitle: string;
    deckTitle: string;
    deckId: string;
}

function Breadcrumbs(props: BreadcrumbsProps) {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active"><Link to={`/group/${props.slug}`}>{props.groupTitle}</Link></li>
                <li className="breadcrumb-item active"><Link to={`/group/${props.slug}/decks`}>Decks</Link></li>
                <li className="breadcrumb-item active"><Link to={`/group/${props.slug}/decks/edit/${props.deckId}`}>{props.deckTitle}</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Editor</li>
            </ol>
        </nav>
    );
}

export default Breadcrumbs;