import React, {useState, useMemo, MutableRefObject, useCallback} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import {fetchJson, postJson} from "../../Util";
import {TypeComputedProps, TypeEditInfo} from "@inovua/reactdatagrid-community/types";
import Breadcrumbs from "./Breadcrumbs";
import LayoutFullScreen from "../LayoutFullScreen";
import {GridCard, DeckData} from "./Types";
import {GetFilterValue} from "./Filters";
import {GetColumns} from "./Columns";
import TagCategories from "./TagCategories";
import Toolbar from "./Toolbar";
import {findDuplicates} from "./Duplicates";
import {progress} from "../../Util";

interface DeckEditorProps
{
    groupId: string;
    deckId: string;
}

function DeckEditor({ groupId, deckId } : DeckEditorProps) {
    const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
    const [selectedRow, setSelectedRow] = useState<string | null>(null);
    const [enableFiltering, setEnableFiltering] = useState(false);
    const [showTagCategories, setShowTagCategories] = useState(false);
    const queryClient = useQueryClient();

    const query  = useQuery<DeckData, Error>(['deck', `${groupId}~${deckId}`], () =>
        fetchJson(`/api/group/${groupId}/decks/editor/${deckId}`)
    );
    
    const invalidateDeckOnSuccess = {
        onSuccess: () => {
            queryClient.invalidateQueries('deck');
        }
    };

    const editFn = (data: TypeEditInfo) => postJson(`/api/group/${groupId}/decks/editor/${deckId}/edit`, { rowId: data.rowId, columnId: data.columnId, value: data.value } );
    const editMutation = useMutation<void, Error, TypeEditInfo>(editFn, invalidateDeckOnSuccess);

    const renameTagCategoryFn = ({ id, name }: { id: string, name: string }) => postJson(`/api/group/${groupId}/decks/editor/${deckId}/rename-tag-category`, { tagCategoryId: id, name } );
    const renameTagCategoryMutation = useMutation<void, Error, { id: string, name: string }>(renameTagCategoryFn, invalidateDeckOnSuccess);

    const deleteTagCategoryFn = (id: string) => postJson(`/api/group/${groupId}/decks/editor/${deckId}/delete-tag-category/${id}`);
    const deleteTagCategoryMutation = useMutation<void, Error, string>(deleteTagCategoryFn, invalidateDeckOnSuccess);

    const addTagCategoryFn = (name: string) => postJson(`/api/group/${groupId}/decks/editor/${deckId}/add-tag-category/${name}`);
    const addTagCategoryMutation = useMutation<void, Error, string>(addTagCategoryFn, invalidateDeckOnSuccess);

    const swapTagCategoriesFn = ({ id1, id2 } : { id1: string, id2: string }) => postJson(`/api/group/${groupId}/decks/editor/${deckId}/swap-tag-categories/${id1}/${id2}`);
    const swapTagCategoriesMutation = useMutation<void, Error, { id1: string, id2: string }>(swapTagCategoriesFn, invalidateDeckOnSuccess);

    const addCardFn = () => postJson(`/api/group/${groupId}/decks/editor/${deckId}/add-card`);
    const addCardMutation = useMutation<{ success: boolean, cardId: string }, Error, string>(addCardFn, {
        onSuccess: async data => {
            if (!data.success) return;
            await queryClient.invalidateQueries('deck');
            // This setTimeout works around a race condition where even though the grid definitely has
            // a row for the new cardId (as can be seen using gridRef.current.getItemIndexById(id)),
            // it won't scroll _quite_ to the end.
            setTimeout(() => {
                scrollTo(data.cardId);
            }, 500);
        }
    });

    const deleteCardFn = (id: string) => postJson(`/api/group/${groupId}/decks/editor/${deckId}/delete-card/${id}`);
    const deleteCardMutation = useMutation<void, Error, string>(deleteCardFn, invalidateDeckOnSuccess);

    const tagCategories = query.data?.tagCategories;

    const columns = useMemo(() => GetColumns(gridRef, tagCategories), [gridRef, tagCategories]);
    const filterValue = useMemo(() => GetFilterValue(tagCategories), [tagCategories]);

    const scrollTo = useCallback((id) => {
        if (gridRef?.current == null) return;
        gridRef.current.scrollToId(id);
    }, [gridRef]);

    const onSelectionChange = useCallback(({ selected }) => {
        setSelectedRow(selected);
    }, []);

    if (query.isLoading || query.isIdle) return <p>Loading...</p>;

    if (query.error) {
        if (query.error.message === 'Status 404') return <p>Not found.</p>;
        if (query.error.message === 'Status 403') return <p>Permission denied.</p>;
        return <p>Sorry, something went wrong.</p>;
    }

    const data = query.data;

    const onEditComplete = (data: TypeEditInfo) => {
        editMutation.mutate(data);
    };

    const toggleFilters = () => {
        setEnableFiltering(!enableFiltering);
    };

    const toggleShowTagCategories = () => {
        setShowTagCategories(!showTagCategories);
    };
    
    const normalise = (input: string) => input == null ? "" : input.toLowerCase().trim();
    
    const frontDuplicates = findDuplicates(data.cards.map(c => normalise(c.front)));
    const backDuplicates = findDuplicates(data.cards.map(c => normalise(c.back)));

    const gridDataSource = data.cards.map(c => {
        const card: GridCard = {
            ...c,
            isDuplicateFront: frontDuplicates.indexOf(normalise(c.front)) > -1,
            isDuplicateBack: backDuplicates.indexOf(normalise(c.back)) > -1,
            studentCount: data.studentCount,
            progress: progress(c.memorised, c.reviewed, data.studentCount)
        };
        for (const tagCategory of data.tagCategories) {
            card[tagCategory.tagCategoryId] = c.tags[tagCategory.tagCategoryId]?.[0] || '';
        }
        return card;
    });
    
    const tagCategoryTags: {[key: string]: string[]} = {};
    for (const tagCategory of data.tagCategories) {
        const tags: string[] = [];
        for (const card of data.cards) {
            const tag = card.tags[tagCategory.tagCategoryId]?.[0];
            if (!tag || tags.indexOf(tag) > -1) continue;
            tags.push(tag);
        }
        tagCategoryTags[tagCategory.tagCategoryId] = tags;
    }
    
    const renameTagCategory = (id: string, name: string) => {
        renameTagCategoryMutation.mutate({ id, name });
    };
    
    const deleteTagCategory = (id: string) => {
        deleteTagCategoryMutation.mutate(id);
    };
    
    const addTagCategory = (name: string) => {
        addTagCategoryMutation.mutate(name);
    };
    
    const swapTagCategories = (id1: string, id2: string) => {
        swapTagCategoriesMutation.mutate({ id1, id2 });
    };
    
    const addCard = () => {
        addCardMutation.mutate("");
    };
    
    const deleteCard = () => {
        if (!selectedRow) return;
        if (window.confirm(`Are you sure you want to delete this card?\n\nThis cannot be undone.`)) {
            deleteCardMutation.mutate(selectedRow);
        }
    };

    return (
        <LayoutFullScreen>
            <Breadcrumbs slug={data.slug} groupTitle={data.groupTitle} deckTitle={data.deckTitle} deckId={deckId}/>
            {showTagCategories ?
                <TagCategories
                    tagCategories={tagCategories || []}
                    toggleShowTagCategories={toggleShowTagCategories}
                    renameTagCategory={renameTagCategory}
                    deleteTagCategory={deleteTagCategory}
                    addTagCategory={addTagCategory}
                    swapTagCategories={swapTagCategories}
                />
                :
                <>
                    <Toolbar
                        toggleFilters={toggleFilters}
                        toggleShowTagCategories={toggleShowTagCategories}
                        enableFiltering={enableFiltering}
                        addCard={addCard}
                        deleteCard={deleteCard}
                        isCardSelected={selectedRow != null}
                        status={editMutation.isError ? 'An error occurred' : ''}
                        isLoading={editMutation.isLoading}
                        classroomUrl={`https://classroom.remembermore.app/${groupId}/${deckId}`}
                    />
                    {tagCategories != null && tagCategories.map(tc => (
                        <datalist id={tc.tagCategoryId} key={tc.tagCategoryId}>
                            {tagCategoryTags[tc.tagCategoryId].map(t => <option key={t} value={t} />)}
                        </datalist>
                    ))}
                    <ReactDataGrid
                        onReady={setGridRef}
                        idProperty="cardId"
                        columns={columns}
                        dataSource={gridDataSource}
                        className="deck-editor"
                        showColumnMenuTool={true}
                        showColumnMenuLockOptions={false}
                        showColumnMenuGroupOptions={false}
                        defaultFilterValue={filterValue}
                        enableFiltering={enableFiltering}
                        editable={true}
                        onEditComplete={onEditComplete}
                        selected={selectedRow}
                        onSelectionChange={onSelectionChange}
                    />
                </>
            }
        </LayoutFullScreen>
    );
}

export default DeckEditor;