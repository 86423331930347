import React from 'react';
import {useParams} from 'react-router-dom';
import DeckEditor from '../components/deck-editor/DeckEditor';

interface UrlParams {
    groupId: string;
    deckId: string;
}

function DeckEditorScreen() {
    const { groupId, deckId } = useParams<UrlParams>();

    return (
        <DeckEditor groupId={groupId} deckId={deckId} />
    );
}

export default DeckEditorScreen;