export function findDuplicates(input: string[]): string[] {
    const sortedInput = [...input].sort();
    if (sortedInput.length <= 1) return [];
    
    const result: string[] = [];
    for (let i = 1; i < sortedInput.length; i++) {
        if (sortedInput[i].length === 0) continue;
        if (sortedInput[i - 1] === sortedInput[i]) {
            if (result.length === 0 || result[result.length - 1] !== sortedInput[i]) {
                result.push(sortedInput[i]);
            }
        }
    }
    return result;
}